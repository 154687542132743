.logo ion-thumbnail {
    width: 200px;
    height: auto;
    margin-left: 25px;
}

.logo ion-thumbnail ion-img {
    object-fit: contain;
    vertical-align: middle;
    margin: 30px 0;
}

.ion-page ion-header {
    height: 100px;
    background-color: #F1F1F1;
    margin-bottom: 100px;

    height: auto;
    margin-bottom: 2rem;
}

.ion-page ion-header ion-toolbar {
    --background: transparent;
}

/*
.ion-page ion-header ion-toolbar:after { 
    content: 'Produktkatalog';
    display: block;
    min-height: 50px;
    width: 100%;
    margin-top: -2px;
    background-color: #c32000;
    border-color: #901800;

    padding-left: calc(calc(calc(9.65 / var(--ion-grid-columns, 12)) * 100%));
    line-height: 50px;
    font-size: 2rem;
    font-weight: 500;
    color: #ffffff;
}
*/

ion-item {
    --background: #ffffff;
    --color: #555;
    --border-width: 0 0 0 0;
    --highlight-height: 0;
    
    background-image: none;
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
  
ion-button {
    --background: #c32000;
    --color: #ffffff;
    
    border-color: #aa1c00;
    /*height: 2.5em;*/
    height: 3.125em;
    font-size: medium;
} 

.banner {
    background-color: #c32000;
    border-color: #901800;
    min-height: 50px;
}

.banner ion-col {
    text-align: right;
    font-size: 2rem;
    font-weight: 500;
    color: #ffffff;
}

.auto-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 0 1.125rem;
    margin: 4rem -10px 4rem;
}

.auto-grid ion-card  {
    --background: #ffffff;
    --color: #555;
    border: 1px solid #ccc;
    width: 100%;
}

.auto-grid ion-card ion-card-header ion-card-title {
    --color: #555;
}

.auto-grid ion-card ion-card-content table {
    width: 100%;
}

.auto-grid ion-card ion-card-content table td {
    padding: 0.25rem 0;
}

.pswp__caption__center {
    text-align: center;
    color: #fff;
    font-size: 1.5rem;
    padding: 1rem 0;
}

.pswp__error-msg {
    color: #fff;
    font-size: 2rem;
}

.pswp__error-msg a {
    color: #fff;
}

.no-results {
    grid-column: 1/5;
}

.no-results p {
    width: 100%;
    text-align: center;
    font-size: 1.5rem;
    color: #aaaaaa;
}

.clearInput {
    height: 2rem;
    --box-shadow: none;
    --background: #ffff;
    --color: #ccc;
}